<template>
  <!-- Card Header -->
  <div
    v-bind:class="
      background
        ? 'card-header pt-4 pb-4 custom-header-bg'
        : 'card-header pt-4 pb-4'
    "
  >
    <div class="row align-items-center">
      <Actions v-bind:items="actions" />
      <BreadCrumb
        v-if="breadCrumb && breadCrumb.length"
        v-bind:bread-crumb="breadCrumb"
      />
      <Title v-else-if="title && title.length" v-bind:title="title" />
    </div>
  </div>
  <!-- ./Card Header -->
</template>

<script>
const Actions = () => import("./components/Actions/Index");
const BreadCrumb = () => import("./components/BreadCrumb/Index");
const Title = () => import("./components/Title/Index");

export default {
  name: "Header",
  components: {
    Actions,
    BreadCrumb,
    Title,
  },
  props: ["background", "actions", "bread-crumb", "title"],
};
</script>
